import React, { Fragment } from "react";

import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Divider,
  Drawer,
  ListItemIcon
} from "@material-ui/core";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LibraryAddOutlinedIcon from "@material-ui/icons/LibraryAddOutlined";

import PropTypes from "prop-types";

const menuData = {
  home: {
    title: "Home",
    url: "/",
    icon: <HomeOutlinedIcon />,
    dividerAfter: true
  },

  selfAwb: {
    title: "Creaza AWB",
    url: "/awb/add",
    icon: <LibraryAddOutlinedIcon />
  }
};

const MyMenu = ({ isOpen, hideMenu }) => {
  const styles = useStyles();

  const createMenuItem = (key, item) => {
    return (
      <Fragment key={key}>
        <ListItem button onClick={() => console.log("PRESSED")}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItem>
        {item.dividerAfter && <Divider />}
      </Fragment>
    );
  };

  return (
    <Drawer anchor="left" variant="temporary" open={isOpen} onClick={hideMenu}>
      <div className={styles.menu} role="presentation">
        <List>
          {Object.keys(menuData).map(key => createMenuItem(key, menuData[key]))}
        </List>
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles({
  menu: {
    width: 240
  }
});

MyMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideMenu: PropTypes.func.isRequired
};

export default MyMenu;
