import React, { useState } from "react";
import Header from "./Header";
import { makeStyles } from "@material-ui/core";
import MyMenu from "./MyMenu";

const AppLayout = ({ children }) => {
  const [displayMenu, setDisplayMenu] = useState(false);

  const styles = useStyles();

  const showMenu = () => {
    setDisplayMenu(true);
  };

  const hideMenu = () => {
    setDisplayMenu(false);
  };

  return (
    <div className={styles.root}>
      <Header onMenuPress={showMenu} />
      <MyMenu isOpen={displayMenu} hideMenu={hideMenu} />
      {children}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    flex: 1
  }
});

export default AppLayout;
