import React from "react";

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import PropTypes from "prop-types";

const Header = ({onMenuPress}) => {
  const styles = useStyles();

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onMenuPress}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={styles.title}>
          HDS Transport
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(2)
  }
}));

Header.propTypes = {
  onMenuPress: PropTypes.func.isRequired
};

export default Header;
