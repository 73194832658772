import React from "react";

import AppLayout from "./layout/AppLayout";
import { makeStyles } from "@material-ui/core";

const App = () => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <AppLayout>CONTENT...</AppLayout>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%"
  }
});

export default App;
